var render = function render(){var _vm=this,_c=_vm._self._c;return _c('form',{ref:"profile_form",on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_ORGANIZATIONS))?_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.ORGANIZATION')} (*)`,"placeholder":_vm.$t('COMMON.ORGANIZATION')}},[_c('organization-selector',{attrs:{"organization":_vm.warehouse.organization.id,"filterable":true,"showAll":false,"disabled":!!_vm.warehouse.id},on:{"organizationChanged":(organizationId, organization) => {
          _vm.warehouse.organization.id = organizationId;
          _vm.warehouse.allowedLocations = [];
          _vm.warehouse.taxes = organization.taxes;
          _vm.warehouse.serviceCenter.id = null;
          _vm.warehouse.establishment.id = null;
          _vm.warehouse.cafeteria.id = null;
          _vm.onFormChanged();
        }}})],1):_vm._e(),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.organization}}),(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_LOCATIONS))?_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.LOCATION')}`,"placeholder":_vm.$t('COMMON.LOCATION')}},[_c('locations-selector',{attrs:{"locations":_vm.warehouse.allowedLocations,"filterable":true,"showAll":false,"multiple":true,"organization":_vm.warehouse.organization.id,"disabled":_vm.warehouse.locked},on:{"locationsChanged":(locations) => {
          _vm.warehouse.allowedLocations = locations;
          _vm.onFormChanged();
        }}})],1):_vm._e(),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.location}}),_c('base-checkbox',{staticClass:"mb-3",attrs:{"disabled":_vm.warehouse.locked},on:{"change":() => {
        _vm.onFormChanged();
      }},model:{value:(_vm.warehouse.is_model),callback:function ($$v) {_vm.$set(_vm.warehouse, "is_model", $$v)},expression:"warehouse.is_model"}},[_c('span',{staticClass:"form-control-label"},[_vm._v(" "+_vm._s(_vm.$t("WAREHOUSES.WAREHOUSE_IS_MODEL"))+" ")])]),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.is_model}}),(!_vm.warehouse.is_model)?[_c('base-input',{attrs:{"label":`${_vm.$t('WAREHOUSES.WAREHOUSE_MODEL_USED')} (*)`,"placeholder":_vm.$t('WAREHOUSES.WAREHOUSE_MODEL_USED')},on:{"change":_vm.onFormChanged}},[_c('warehouse-selector',{attrs:{"allowNone":true,"multiple":false,"showAll":false,"warehouse":_vm.warehouse.modelUsed ? _vm.warehouse.modelUsed.id : null,"isOnlyModels":true},on:{"warehouseChanged":(warehouseId, modelSelected) => {
            if (_vm.warehouse.modelUsed) {
              _vm.warehouse.modelUsed.id = warehouseId;
            } else {
              _vm.warehouse.modelUsed = { type: 'warehouses', id: warehouseId };
            }
            _vm.onFormChanged();
          }}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.modelUsed}})]:_vm._e(),(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_SERVICECENTERS))?_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.SERVICECENTER')} `,"placeholder":_vm.$t('COMMON.SERVICECENTER')}},[_c('service-center-selector',{attrs:{"serviceCenter":_vm.warehouse.serviceCenter ? _vm.warehouse.serviceCenter.id : null,"filterable":true,"showAll":false,"filterOrganization":_vm.warehouse.organization.id,"disabled":_vm.warehouse.locked},on:{"serviceCenterChanged":(serviceCenter) => {
          _vm.warehouse.serviceCenter = {
            type: 'service-centers',
            id: serviceCenter,
          };
          _vm.warehouse.establishment = {
            type: 'establishments',
            id: null,
          };
          _vm.warehouse.cafeteria = {
            type: 'cafeterias',
            id: null,
          };
          _vm.onFormChanged();
        }}})],1):_vm._e(),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.serviceCenter}}),(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_ESTABLISHMENTS))?_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.ESTABLISHMENT')} `,"placeholder":_vm.$t('COMMON.ESTABLISHMENT')}},[_c('establishment-selector',{attrs:{"establishment":_vm.warehouse.establishment ? _vm.warehouse.establishment.id : null,"filterable":true,"showAll":false,"filterOrganization":_vm.warehouse.organization.id,"filterServiceCenter":_vm.warehouse.serviceCenter?.id,"disabled":!_vm.warehouse.serviceCenter?.id || _vm.warehouse.locked},on:{"establishmentChanged":(establishment) => {
          _vm.warehouse.establishment = {
            type: 'establishments',
            id: establishment,
          };
          _vm.warehouse.cafeteria = {
            type: 'cafeterias',
            id: null,
          };
          _vm.onFormChanged();
        }}})],1):_vm._e(),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.establishment}}),(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_CAFETERIAS))?_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.CAFETERIA')} `,"placeholder":_vm.$t('COMMON.CAFETERIA')}},[_c('cafeteria-selector',{attrs:{"disabled":!_vm.warehouse.establishment?.id || _vm.warehouse.locked,"cafeteria":_vm.warehouse.cafeteria ? _vm.warehouse.cafeteria.id : null,"filterable":true,"showAll":false,"filterOrganization":_vm.warehouse.organization.id,"filterServiceCenter":_vm.warehouse.serviceCenter?.id,"filterEstablishment":_vm.warehouse.establishment?.id},on:{"cafeteriaChanged":(cafeteria) => {
          _vm.warehouse.cafeteria = {
            type: 'cafeterias',
            id: cafeteria,
          };
          _vm.onFormChanged();
        }}})],1):_vm._e(),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.cafeteria}}),_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.NAME')} (*)`,"placeholder":_vm.$t('COMMON.NAME'),"disabled":_vm.warehouse.locked},on:{"change":_vm.onFormChanged},model:{value:(_vm.warehouse.name),callback:function ($$v) {_vm.$set(_vm.warehouse, "name", $$v)},expression:"warehouse.name"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.name}}),(!_vm.warehouse.modelUsed?.id)?_c('base-checkbox',{staticClass:"mb-3",on:{"change":() => {
        _vm.onFormChanged();
      }},model:{value:(_vm.warehouse.allow_negative_inventory),callback:function ($$v) {_vm.$set(_vm.warehouse, "allow_negative_inventory", $$v)},expression:"warehouse.allow_negative_inventory"}},[_c('span',{staticClass:"form-control-label"},[_vm._v(" "+_vm._s(_vm.$t("WAREHOUSES.ALLOW_NEGATIVE_INVENTORY"))+" ")])]):_vm._e(),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.allow_negative_inventory}}),(!_vm.warehouse.modelUsed?.id)?_c('base-checkbox',{staticClass:"mb-3",on:{"change":() => {
        _vm.onFormChanged();
      }},model:{value:(_vm.warehouse.allow_unregistered_products),callback:function ($$v) {_vm.$set(_vm.warehouse, "allow_unregistered_products", $$v)},expression:"warehouse.allow_unregistered_products"}},[_c('span',{staticClass:"form-control-label"},[_vm._v(" "+_vm._s(_vm.$t("WAREHOUSES.ALLOW_UNREGISTERED_PRODUCTS"))+" ")])]):_vm._e(),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.allow_unregistered_products}}),_c('base-checkbox',{staticClass:"mb-3",model:{value:(_vm.warehouse.use_warehouse_taxes),callback:function ($$v) {_vm.$set(_vm.warehouse, "use_warehouse_taxes", $$v)},expression:"warehouse.use_warehouse_taxes"}},[_c('span',{staticClass:"form-control-label"},[_vm._v(" "+_vm._s(_vm.$t("WAREHOUSES.USE_WAREHOUSE_TAXES"))+" ")])]),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.use_warehouse_taxes}}),(!_vm.warehouse.modelUsed?.id && _vm.warehouse.use_warehouse_taxes)?_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.TAX_GROUPS')}`,"placeholder":_vm.$t('COMMON.TAX_GROUPS')}},[_c('tax-groups-selector',{attrs:{"taxGroups":_vm.warehouse.taxGroups},on:{"taxGroupsChanged":(taxGroups) => {
          _vm.warehouse.taxGroups = taxGroups;
        }}})],1):_vm._e(),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.taxGroups}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.EXCERPT')}`,"placeholder":_vm.$t('COMMON.EXCERPT')}},[_c('html-editor',{on:{"change":function($event){return _vm.onFormChanged()}},model:{value:(_vm.warehouse.excerpt),callback:function ($$v) {_vm.$set(_vm.warehouse, "excerpt", $$v)},expression:"warehouse.excerpt"}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.excerpt}})],1)]),_c('div',{staticClass:"my-4"},[_c('base-button',{staticClass:"btn btn-sm btn-primary",attrs:{"type":"button","native-type":"submit","disabled":_vm.loading}},[(_vm.loading)?_c('i',{staticClass:"fas fa-spinner fa-spin"}):_vm._e(),_vm._v(" "+_vm._s(_vm.warehouse.id ? _vm.$t("WAREHOUSES.EDIT_WAREHOUSE") : _vm.$t("WAREHOUSES.ADD_WAREHOUSE"))+" ")])],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }